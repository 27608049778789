import moment from 'moment';

/**
 * Reverse the comparator parameters (ASC -> DESC or DESC -> ASC)
 * @param {Function} comparator
 * @returns {Function}
 */
export const reverse = comparator => (a, b) => comparator(b, a);

export const naturalSortAsc  = (a, b) => a.toString().localeCompare(b, undefined, {numeric: true, sensitivity: 'base'});
export const naturalSortDesc = reverse(naturalSortAsc);

export const numberSortAsc  = (a, b) => a < b ? -1 : a > b ? 1 : 0;
export const numberSortDesc = reverse(numberSortAsc);

export const dateSortAsc  = (a, b) => numberSortAsc(
    a.date ? moment(a.date) : moment(a),
    b.date ? moment(b.date) : moment(b)
);
export const dateSortDesc = reverse(dateSortAsc);

export const bTableSortCompare = (a, b, key) => {
    // If we have moment values, compare them as if they were numbers,
    // moment will automatically cast them into timestamps
    if (moment.isMoment(a[key]) && moment.isMoment(b[key])) {
        return numberSortAsc(a[key], b[key]);
    }

    return null; // Fall back to default behaviour
};

export default {naturalSortAsc, naturalSortDesc, numberSortAsc, numberSortDesc, bTableSortCompare};